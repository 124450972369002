import React from "react";
import { graphql } from "gatsby";
import Container from "../components/Container";
export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Container edges>
      <h1 className="text-2xl font-bold pb-4">{frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Container>
  );
}
export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`;
